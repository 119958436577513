import { startCase } from 'lodash';
import { providerTypes } from '../components/advanced-settings/integrations/lms-integration';

/* @ngInject */
export default function ConfigureLmsModalCtrl(
  AlertMessages,
  $uibModalInstance,
  InstitutionsManager,
  $translate,
  MigratedFields,
  config,
) {
  const vm = this;

  vm.InstitutionsManager = InstitutionsManager;
  vm.currentStep = 1;
  vm.isSaving = false;
  vm.formErrorMessage = '';
  vm.config = config;

  vm.providerType = vm.InstitutionsManager.institution?.lmsConnectionSettings
    ? vm.InstitutionsManager.institution.lmsConnectionSettings.lms : '';
  vm.apiEndPoint = '';
  vm.clientId = '';
  vm.clientSecret = '';
  vm.username = '';
  vm.password = '';
  vm.domains = [];
  vm.learnUponModuleId = '';

  vm.selectedDomain = null;
  vm.sabaAutoAssign = null;
  vm.selectedUsernameType = null;

  vm.selectedOrgProfile = null;

  vm.moduleIdHelpLink = 'https://help.novoed.com/hc/en-us/articles/34948778252692';
  vm.domainPlaceholder = 'https://yourdomain.learnupon.com';

  vm.providerTypes = {
    csod: 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.PROVIDER_TYPE.CSOD',
    learn_upon: 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.PROVIDER_TYPE.LEARNUPON',
    saba: 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.PROVIDER_TYPE.SABA',
    sum_total: 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.PROVIDER_TYPE.SUMTOTAL',
  };

  vm.usernameTypes = [
    {
      id: 'email',
      value: 'email',
      text: $translate.instant('INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.SABA.STEP_2.USERNAME_TYPE_EMAIL'),
      settingsId: null,
    },
  ];

  initialize();

  function initialize() {
    if (vm.InstitutionsManager.institution?.ssoLogin) {
      vm.usernameTypes.push({
        id: 'external_id',
        value: 'external_id',
        text: $translate.instant('INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.SABA.STEP_2.USERNAME_TYPE_EXTERNAL'),
        settingsId: null,
      });
    }

    if (vm.InstitutionsManager.institution?.profileSettings?.orgLevel.length > 0) {
      vm.InstitutionsManager.institution?.profileSettings?.orgLevel.forEach((orgLevelField) => {
        const { id, name, migratedField } = orgLevelField;

        vm.usernameTypes.push({
          id: `profile_setting_${id}`,
          value: 'profile_setting',
          text: (migratedField && !name)
            ? MigratedFields.getDisplayName(migratedField)
            : startCase(name),
          settingsId: id,
        });
      });
    }
  }

  vm.closeModal = (isLmsStepTwoCompleted = true) => {
    if ((vm.currentStep > 1 && !InstitutionsManager.institution.lmsConnectionSettings && isLmsStepTwoCompleted)
      || vm.csodEnabled
      || vm.learnUponEnabled
    ) {
      InstitutionsManager.institution.lmsConnectionSettings = {
        lms: vm.providerType,
        clientEndpoint: vm.apiEndPoint,
        clientId: vm.clientId,
        clientSecret: vm.clientSecret,
      };
    }
    $uibModalInstance.dismiss('cancel');
  };

  vm.setProviderType = (type) => {
    vm.providerType = type;

    if (vm.providerType === providerTypes.CSOD) {
      vm.orgProfiles = vm.InstitutionsManager.institution?.profileSettings?.orgLevel?.filter(({ name }) => name);
      vm.orgProfiles.unshift({
        name: 'Email',
        value: 'email',
      });
    }
  };

  vm.saveAuthentication = () => {
    vm.isSaving = true;
    vm.formErrorMessage = '';

    InstitutionsManager.saveLmsAuthentication(
      vm.providerType,
      vm.clientId,
      vm.clientSecret,
      vm.apiEndPoint,
      vm.username,
      vm.password,
      vm.selectedOrgProfile,
      vm.learnUponModuleId,
    )
      .then(({ result: { domains } }) => {
        if (vm.providerType === providerTypes.SABA) {
          vm.domains = domains;
          vm.currentStep = 2;
        } else if (vm.providerType === providerTypes.SUMTOTAL) {
          vm.orgProfiles = vm.InstitutionsManager.institution?.profileSettings?.orgLevel?.filter(({ name }) => name);
          vm.currentStep = 2;
        } else if (vm.providerType === providerTypes.CSOD || vm.providerType === providerTypes.LEARNUPON) {
          vm.orgProfiles = null;
          vm.selectedOrgProfile = null;
          vm.csodEnabled = vm.providerType === providerTypes.CSOD;
          vm.learnUponEnabled = vm.providerType === providerTypes.LEARNUPON;
          vm.closeModal();
          AlertMessages.success('FORM.SUCCESS', 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.LMS_ENABLED');
        }
      })
      .catch(() => {
        AlertMessages.error(
          'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.ERROR_TITLE',
          'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.MODAL.ERROR',
        );
      })
      .finally(() => { vm.isSaving = false; });
  };

  vm.setOrgProfile = (profile) => {
    vm.selectedOrgProfile = profile;
  };

  vm.setDomain = (domain) => {
    vm.selectedDomain = domain;
  };

  vm.setUsernameType = (usernameType) => {
    vm.selectedUsernameType = usernameType;
  };

  vm.saveDomain = () => {
    vm.isSaving = true;
    vm.formErrorMessage = '';
    if (vm.providerType === providerTypes.SUMTOTAL) {
      vm.selectedUsernameType = {
        value: 'profile_setting',
        settingsId: vm.selectedOrgProfile.id,
      };
    }
    InstitutionsManager.saveSabaDomain(vm.selectedDomain, vm.sabaAutoAssign, vm.selectedUsernameType)
      .then(() => {
        InstitutionsManager.institution.lmsConnectionSettings = {
          lms: vm.providerType,
          clientEndpoint: vm.apiEndPoint,
          sabaAutoAssignNumbers: vm.sabaAutoAssign,
          sabaDomainName: vm.selectedDomain?.name,
        };
        vm.closeModal();
        AlertMessages.success('FORM.SUCCESS', 'INSTITUTIONS.ADVANCED_SETTINGS.LMS_INTEGRATION.LMS_ENABLED');
      })
      .catch(({ data: { errors }, status }) => {
        if (status === 422 && errors) {
          vm.formErrorMessage = errors;
        } else {
          AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
        }
      })
      .finally(() => { vm.isSaving = false; });
  };

  vm.onAuthorizeClick = () => (
    vm.authorizeForm.$dirty && !vm.authorizeForm.$invalid && !vm.isSaving && vm.saveAuthentication()
  );
}
